<template>
    <div class="audit-btn">
      <el-button type="text" @click="candleHandle">取消</el-button>
      <el-button type="danger" :loading="rejectLoading" @click="rejectHandle">拒绝</el-button>
      <el-button type="success" :loading="passLoading"  @click="passAndSendhandle">通过并发送</el-button>
    </div>
</template>

<script>
  import { updateMassStatus } from '@/api/notificationManager/mass'
  export default {
    name: 'auditBtn',
    props: {
      rowId: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        rejectLoading: false,
        passLoading: false,
      }
    },
    methods: {
      close(){
        this.$emit('close-dialog');
      },
      candleHandle() {
        this.close();
      },
      rejectHandle() {
        // ajax
        this.rejectLoading = true;
        updateMassStatus({
          id: this.rowId,
          status: 4,
        }).then((res) => {
          this.rejectLoading = false;
          this.$message({
            type: 'success',
            message: '拒绝成功'
          });
          setTimeout(() => {
            this.$emit('ajax-list');
          },1000);
        }).then((error) => {
          this.rejectLoading = false;
        })
      },
      passAndSendhandle() {
        // ajax
        this.passLoading = true;
        updateMassStatus({
          id: this.rowId,
          status: 3,
        }).then((res) => {
          this.passLoading = false;
          this.$message({
            type: 'success',
            message: '通过成功'
          });
          setTimeout(() => {
            this.$emit('ajax-list');
          },1000);
        }).then((error) => {
          this.passLoading = false;
        })
      },
    }
  }
</script>

<style scoped>

</style>
